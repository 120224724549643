// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Form from "./Form";

function CTA() {
  return (
    <Box sx={{ backgroundColor: "primary.main500" }} id="about">
      
<Container maxWidth="lg">
        {/* Text Section */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
        <Typography variant="body1">
        The Spinluckbet team is composed of a diverse group of talented individuals who share a common love for gaming. Our developers work tirelessly to bring you the latest and greatest games, while our support staff ensures that your experience on our platform is smooth and enjoyable. We believe in creating a collaborative and innovative environment where ideas can flourish and where every team member contributes to our shared success.        </Typography>
          </Box>
        </Grow>
        <Form/>
      </Container>
    </Box>
  );
}

export default CTA;
